import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const AdvancedManufacturing = ({ data }) => {
  return (
    <>
      <Seo
        title="Work in North Central Pennsylvania: Advanced Manufacturing"
        description="The PA Six region of North Central Pennsylvania is known as the Powdered Metal Capital of the World serving businesses and industries throughout the nation and the world."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption="Advanced Powder Products - Clearfield County"
        position="center"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Advanced Manufacturing</h2>
            <p>
              The top industry in the PA Six region with over 18,000 people
              employed in the industry.
            </p>
            <p>
              The region is known as the{" "}
              <i>Powdered Metal Capital of the World</i> serving businesses and
              industries throughout the nation and the world.
            </p>
          </div>

          <div className="intro-text">
            <p>
              The strength of the manufacturing industry in PA SIX is
              increasingly focused on advanced additive, high-tech and digital
              strategies to meet the demands of modern businesses and consumers.
            </p>
            <p>
              The Advanced Manufacturing (AM) cluster is made up of mostly
              durable goods manufacturing but also includes wholesalers, and
              some retailers such as car dealers. Other industry clusters
              contain segments of manufacturing within them, while the AM
              cluster is focused around its four sub-clusters:
            </p>
            <ul>
              <li>Chemicals, Rubber & Plastics (AM- CRP)</li>
              <li>Electronics (AM-E)</li>
              <li>Metals (AM-M)</li>
              <li>Vehicles & Vehicle Equipment (AM-VVE)</li>
            </ul>
          </div>
        </section>

        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption="Advanced Powder Products, Clearfield County"
        />
      </main>

      <PathwaysIntro
        industry="Advanced Manufacturing"
        image={data.pathwaysImage.sharp.image}
        caption="Miller Fabrication Solutions, Jefferson County"
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const query = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: manufacturing {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }

    careerImage: file(relativePath: { eq: "APP_130.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(relativePath: { eq: "CRP7356.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(relativePath: { eq: "APP_232.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "APP_232.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default AdvancedManufacturing;
